








import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useUser } from '@wemade-vsf/customer';
import { LiveChatWidget } from '@livechat/widget-vue/v2';

export default defineComponent({
  name: 'LiveChat',
  components: {
    LiveChatWidget
  },
  props: {
    isCartOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { user } = useUser();
    const customerName = computed(() => user.value?.firstname && user.value.lastname
      ? user.value.firstname + ' ' + user.value.lastname
      : undefined
    );

    const customerEmail = computed(() => user.value?.email ? user.value.email : undefined);
    const visibility = computed(() => props.isCartOpen ? 'hidden' : 'minimized');

    return {
      customerName,
      customerEmail,
      visibility
    }
  }
})
